/* eslint-disable max-len */
import { message } from 'antd';
import { locationDetailsSection, stopDetailsSection } from './eventInfoSections';

export default ({
  sequence, timeSlot, place, position, type,
}, t) => {
  const from = timeSlot.startMoment;
  const to = timeSlot.endMoment;
  window.copyAndNotify = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => message.success(t('COMMON.COPIED_TO_CLIPBOARD')));
  };

  const location = {
    ...place,
    position: {
      latitude: position.lat,
      longitude: position.lng,
    },
    countryCode: place.country,
    street: `${place.street} ${place.houseNumber} ${place.localNumber || ''}`,
  };

  return '<div class="H_bubble_root">'
    + '  <div class="H_bubble_wrapper">'
    + '    <div class="H_bubble_content">'
    + '      <div class="H_bubble_header">'
    + '        <span class="H_bubble_header--title">'
    + `          ${t(`ORDER.STOP_TYPES.${type}`)}`
    + '        </span>'
    + '      </div>'
    + '      <div class="horizontal-line"></div>'
    + `      ${stopDetailsSection(t, {
      from, to, sequence, type,
    })}`
    + `      ${locationDetailsSection(t, { location })}`
    + '    </div>'
    + '    <div class="triangle-with-shadow"></div>'
    + '  </div>'
    + '</div>';
};
