/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox, DatePicker, Form,
} from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CompressedItemWithError, CompressedMixSection, CompressedSection } from '../Styled';
import { DEFAULT_DATE_TIME, DEFAULT_TIME } from '../../utils/constants/timeFormats';

const DatesLabel = styled.span`
  font-weight: 500;
  width: 45px;
`;

const onEndDateSelect = (field, form) => (value) => {
  form.setFields([{
    name: ['stops', field.name, 'endDateTime'],
    value,
  }]);
  form.validateFields([['stops', field.name, 'endDateTime']]);
};

const onStartDateSelect = (fixed, field, form) => (value) => {
  if (fixed || !form.getFieldValue(['stops', field.name, 'endDateTime'])) {
    form.setFields([{
      name: ['stops', field.name, 'endDateTime'],
      value,
    }]);
  }
  form.setFields([{
    name: ['stops', field.name, 'startDateTime'],
    value,
  }]);
  form.validateFields([['stops', field.name, 'endDateTime']]);
};

const endDateValidator = (field, form) => (_, date) => {
  if (date) {
    const startDateTimeField = form.getFieldValue(['stops', field.name, 'startDateTime']);
    const endDateTime = date.clone().startOf('minute');
    if (startDateTimeField) {
      const startDateTime = startDateTimeField.clone().startOf('minute');
      if (endDateTime.isBefore(startDateTime)) {
        return Promise.reject();
      }
    }
  }
  return Promise.resolve();
};

const StopDatesSection = ({ field, form }) => {
  const [fixed, setFixed] = useState(false);
  const { t } = useTranslation();
  const onFixedChange = (newFixed) => {
    setFixed(newFixed);
    if (newFixed) {
      form.setFields([{
        name: ['stops', field.name, 'endDateTime'],
        value: form.getFieldValue(['stops', field.name, 'startDateTime']),
      }]);
      form.setFields([{
        name: ['stops', field.name, 'fixed'],
        value: true,
      }]);
    } else {
      form.setFields([{
        name: ['stops', field.name, 'fixed'],
        value: false,
      }]);
    }
  };

  useEffect(() => {
    if (form && field) {
      onFixedChange(form.getFieldValue(['stops', field.name, 'fixed']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field, form]);

  return (
    <>
      <CompressedSection style={{ marginBottom: '0px' }}>
        <Form.Item
          {...field}
          style={{ minWidth: '120px' }}
          label={t('ORDER.FIXED')}
          name={[field.name, 'fixed']}
        >
          <Checkbox
            checked={fixed}
            style={{ marginLeft: '8px' }}
            onChange={(event) => onFixedChange(event.target.checked)}
          >
            {t('ORDER.FIXED_TIMESLOT')}
          </Checkbox>
        </Form.Item>
      </CompressedSection>
      <CompressedSection style={{ marginLeft: '-65px', marginTop: '10px' }}>
        <DatesLabel>{t('PROPERTIES.FROM:')}</DatesLabel>
        <Form.Item
          {...field}
          label={t('ORDER.START_DATE')}
          key={`${field.key}startDateTime`}
          name={[field.name, 'startDateTime']}
          fieldKey={[field.fieldKey, 'startDateTime']}
          rules={[{
            required: true,
            message: t('ORDER.START_DATE_REQUIRED'),
          }]}
        >
          <DatePicker
            onSelect={onStartDateSelect(fixed, field, form)}
            showTime={{
              format: DEFAULT_TIME,
            }}
            format={DEFAULT_DATE_TIME}
          />
        </Form.Item>
      </CompressedSection>
      <CompressedMixSection style={{ marginLeft: '-65px' }}>
        <DatesLabel>{t('PROPERTIES.TO:')}</DatesLabel>
        <CompressedItemWithError
          {...field}
          label={t('ORDER.END_DATE')}
          key={`${field.key}endDateTime`}
          name={[field.name, 'endDateTime']}
          fieldKey={[field.fieldKey, 'endDateTime']}
          rules={[{
            required: true,
            message: t('COMMON.FILL_IN_FIELDS_ABOVE'),
          }, {
            validator: endDateValidator(field, form),
            message: t('ORDER.END_DATE_MUST_NOT_AFTER_START'),
          }]}
        >
          <DatePicker
            onSelect={onEndDateSelect(field, form)}
            disabled={fixed}
            showTime={{
              format: DEFAULT_TIME,
            }}
            format={DEFAULT_DATE_TIME}
          />
        </CompressedItemWithError>
      </CompressedMixSection>
    </>
  );
};

StopDatesSection.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fieldKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  form: PropTypes.shape({
    getFieldValue: PropTypes.func.isRequired,
    setFields: PropTypes.func.isRequired,
  }).isRequired,
};

export default StopDatesSection;
