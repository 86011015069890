/* eslint-disable react/no-array-index-key */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Divider, Form, Tabs,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusCircleOutlined } from '@ant-design/icons';
import {
  HStretched, StyledTabs,
} from '../../../../components';
import { OrderType } from '../../../../types';
import TimeDetails from '../../../../components/Time/TimeDetails';
import StopSection from '../../../../components/Stop/StopSection';
import FormSection from '../../../../components/Form/FormSection';
import OrderDetailsSection from '../../Components/OrderDetailsSection';
import OrderCarrierDetails from '../../Components/OrderCarrierDetails';

const OrderCreationForm = ({
  companies, commonForm, timeMonitoringForm, tourMonitoringForm, handleSubmit, handleCancel,
}) => {
  const [companyVehicles, setCompanyVehicles] = useState([]);
  const { t } = useTranslation();

  const [orderTypeTab, setOrderTypeTab] = useState(OrderType.TIME);

  const findAvailableVehicles = (companyId) => {
    const selectedCarrier = companies.data.find((company) => company.companyId === companyId);
    if (selectedCarrier) {
      setCompanyVehicles(selectedCarrier.vehicles || []);
      commonForm.setFields([{
        name: 'vehicleId',
        value: null,
      }]);
    }
  };

  const toggleOrderTypeTab = () => {
    if (orderTypeTab === OrderType.TIME) {
      setOrderTypeTab(OrderType.TOUR);
    } else {
      setOrderTypeTab(OrderType.TIME);
    }
  };

  const addNextStop = useCallback((addRow) => {
    const stops = tourMonitoringForm.getFieldValue('stops');
    if (!stops || stops.length === 0) {
      addRow();
    }
    addRow();
  }, [tourMonitoringForm]);

  return (
    <div style={{ display: 'inline-block' }}>
      <Form
        style={{ marginTop: '12px', marginBottom: '40px' }}
        form={commonForm}
        layout="vertical"
      >
        <FormSection title={t('ORDER.NEW_ORDER')}>
          <OrderDetailsSection />
        </FormSection>
        <FormSection title={t('ORDER.CARRIER_DETAILS')}>
          <OrderCarrierDetails
            form={commonForm}
            companies={companies}
            findAvailableVehicles={findAvailableVehicles}
            companyVehicles={companyVehicles}
          />
        </FormSection>
      </Form>
      <FormSection title={t('ORDER.CHOOSE_MONITORING_TYPE')}>
        <StyledTabs
          defaultActiveKey={OrderType.TIME}
          activeKey={orderTypeTab}
          size="small"
          onChange={() => toggleOrderTypeTab()}
        >
          <Tabs.TabPane
            tab={t('ORDER.TIME_MONITORING')}
            key={OrderType.TIME}
          >
            <Form
              form={timeMonitoringForm}
              layout="vertical"
            >
              <FormSection subtitle={t('ORDER.TRIP_TIME_DESCRIPTION')} noDivider>
                <TimeDetails
                  form={timeMonitoringForm}
                  fromDateLabel={t('COMMON.START_DATE')}
                  fromDateRequiredMessage={t('COMMON.START_DATE_REQUIRED')}
                  toDateLabel={t('COMMON.END_DATE')}
                  toDateRequiredMessage={t('COMMON.END_DATE_REQUIRED')}
                  toDateAfterStartMessage={t('COMMON.END_DATE_MUST_NOT_AFTER_START')}
                />
              </FormSection>
            </Form>
          </Tabs.TabPane>

          <Tabs.TabPane
            tab={t('ORDER.TOUR_MONITORING')}
            key={OrderType.TOUR}
          >
            <Form
              form={tourMonitoringForm}
              layout="vertical"
            >
              <FormSection subtitle={t('ORDER.TRIP_DETAILS_DESCRIPTION')} noDivider>
                <Form.List name="stops" initialValue={[{}, {}]}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <StopSection
                          field={field}
                          remove={remove}
                          form={tourMonitoringForm}
                          possibleRemove={index !== 0 && index !== 1}
                          index={index}
                          key={index}
                        />
                      ))}
                      <Divider style={{ marginBottom: '10px' }} />
                      <HStretched _justify="flex-start">
                        <Button
                          type="link"
                          size="large"
                          onClick={() => addNextStop(add)}
                          icon={<PlusCircleOutlined />}
                        >
                          {t('ORDER.ADD_NEXT_STOP')}
                        </Button>
                      </HStretched>
                      <Divider style={{ marginTop: '10px' }} />
                    </>
                  )}
                </Form.List>
              </FormSection>
            </Form>
          </Tabs.TabPane>
        </StyledTabs>

      </FormSection>

      <HStretched _justify="flex-end">
        <Button type="link" size="large" style={{ marginRight: '12px' }} onClick={handleCancel}>
          {t('COMMON.CANCEL')}
        </Button>
        <Button type="primary" size="large" onClick={() => handleSubmit(orderTypeTab)}>
          {t('COMMON.CREATE')}
        </Button>
      </HStretched>
    </div>
  );
};

OrderCreationForm.propTypes = {
  creation: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  companies: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      companyName: PropTypes.string,
      companyId: PropTypes.string,
      vehicles: PropTypes.arrayOf(PropTypes.shape({
        vehicleId: PropTypes.string,
        licencePlateNumber: PropTypes.string,
      })),
    })).isRequired,
  }).isRequired,
  commonForm: PropTypes.shape({
    setFields: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  timeMonitoringForm: PropTypes.shape({
    setFields: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  tourMonitoringForm: PropTypes.shape({
    setFields: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default OrderCreationForm;
