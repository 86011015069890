import React from 'react';
import PropTypes from 'prop-types';
import { Highlighteable } from '../../Text';

const PlaceInfo = ({ place, color }) => {
  const content = (`${place.country}, ${place.postcode} ${place.city}`);

  return (
    <span>
      <Highlighteable
        content={`${place.street} ${place.houseNumber}`}
        color={color}
      />
      <br />
      <Highlighteable
        content={content}
        color={color}
      />
    </span>
  );
};

PlaceInfo.propTypes = {
  place: PropTypes.shape({
    country: PropTypes.string.isRequired,
    postcode: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
    houseNumber: PropTypes.string.isRequired,
  }).isRequired,
  color: PropTypes.string.isRequired,
};

PlaceInfo.defaultProps = {
};

export default PlaceInfo;
