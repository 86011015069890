import React from 'react';
import PropTypes from 'prop-types';
import { stopPlacePropTypes } from '../../../types/stop.type';

const StopLocation = ({ place, inline }) => (
  <span>
    {`${place.street} ${place.houseNumber}`}
    {place.localNumber ? `/${place.localNumber}` : ''}
    {inline ? ', ' : <br />}
    {`${place.country}, ${place.postcode} ${place.city}`}
  </span>
);

StopLocation.propTypes = {
  place: stopPlacePropTypes.isRequired,
  inline: PropTypes.bool,
};

StopLocation.defaultProps = {
  inline: false,
};

export default StopLocation;
