import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import TelematicAccountForm from '../Components/TelematicAccountForm';
import {
  getSelectedCompany,
  telematicAccountForEdit,
} from '../../../store/selectors';
import { getTelematicAccount, updateTelematicAccount } from '../../../store/actions';
import { ERROR_CODE } from '../../../utils/apiError';
import { uuidv4 } from '../../../utils/uuid';
import { isSuccess, getErrorCode, getPayloadData } from '../../../utils/responseUtils';
import { FullSpace, Loader } from '../../../components';

function mapAccountToForm(account) {
  return {
    accountDisplayName: account.accountDisplayName,
    accountDescription: account.accountDescription,
    selectedProvider: account.providerId,
    notFromListProviderCheckbox: false,
    ...account.credentials,
  };
}

export default function TelematicAccountEditPage() {
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const { t } = useTranslation();
  const history = useHistory();

  const [accountEditForm] = Form.useForm();

  const contextCompanyId = useSelector(getSelectedCompany);
  const [submitClicked, setSubmitClicked] = useState(false);

  const {
    fetched: accountIsFetched,
    failed: accountFetchFailed,
    account: accountForEdit,
  } = useSelector(telematicAccountForEdit);

  useEffect(() => {
    dispatch(getTelematicAccount(accountId, contextCompanyId));
  }, [accountId, contextCompanyId, dispatch]);

  useEffect(() => {
    if (accountIsFetched && accountForEdit.companyId !== contextCompanyId) {
      dispatch(() => history.push('/telematics'));
    }
  }, [dispatch, history, contextCompanyId, accountId, accountIsFetched, accountForEdit]);

  const confirmationModalKeysAndConstructor = (response, handleUserNeedsSupport, goBackToTelematicsMain) => {
    const success = isSuccess(response);
    const statusSuffix = success ? 'SUCCESS' : 'ERROR';
    const title = `TELEMATICS.ACCOUNT.UPDATE.CONFIRMATION.TITLE.${statusSuffix}`;
    const content = `TELEMATICS.ACCOUNT.UPDATE.CONFIRMATION.CONTENT.${statusSuffix}`;

    if (success) {
      const payloadData = getPayloadData(response);
      if (payloadData?.details?.failedVins?.length > 0) {
        return {
          title: title.concat('.SCANIA_NOT_ALL_VEHICLES_ADDED'),
          content: <br />,
          constructor: Modal.warning,
          width: 472,
          onOk: goBackToTelematicsMain,
        };
      }
      return {
        title: title.concat('.DEFAULT'),
        content: t(content.concat('.DEFAULT')),
        constructor: Modal.success,
        onOk: goBackToTelematicsMain,
      };
    }

    const errorCode = getErrorCode(response);
    switch (errorCode) {
      case ERROR_CODE.SCANIA_VAT_NUMBER_NOT_FOUND:
      case ERROR_CODE.CONNECTIVITY_AGREEMENT_NOT_SIGNED:
        return {
          title: title.concat(`.${errorCode}`),
          content: t(content.concat(`.${errorCode}`)),
          constructor: Modal.error,
          onOk: handleUserNeedsSupport,
          okText: 'TELEMATICS.ACCOUNT.CREATE.BUTTON_NEED_SUPPORT',
        };
      default:
        return {
          title: title.concat('.DEFAULT'),
          content: t(content.concat('.DEFAULT')),
          constructor: Modal.error,
        };
    }
  };

  const dispatchTelematicAccountUpdate = ({ payload, handleUserNeedsSupport }) => {
    if (submitClicked) {
      return;
    }
    setSubmitClicked(true);
    dispatch(updateTelematicAccount(accountId, payload, contextCompanyId))
      .then((response) => {
        const modalInfo = confirmationModalKeysAndConstructor(
          response,
          handleUserNeedsSupport,
          () => (history.push('/telematics')),
        );
        setSubmitClicked(false);
        modalInfo.constructor({
          key: uuidv4(),
          title: t(modalInfo.title),
          content: modalInfo.content,
          onOk: modalInfo.onOk,
          okText: t(modalInfo.okText),
          centered: true,
          width: modalInfo.width,
        });
      });
  };

  const loaderTextKey = `TELEMATICS.ACCOUNT.UPDATE.LOADER.${accountFetchFailed ? 'FAILED' : 'LOADING'}`;
  const renderLoader = () => <Loader text={t(loaderTextKey)} />;
  const renderErrorMessage = () => (
    <FullSpace height="500px">
      <span style={{ fontSize: '20px' }}>{t(loaderTextKey)}</span>
    </FullSpace>
  );

  // eslint-disable-next-line no-nested-ternary
  return !accountIsFetched ? (accountFetchFailed ? renderErrorMessage() : renderLoader()) : (
    <>
      <BreadcrumbsItem to="/telematics">{t('BREADCRUMB.TELEMATICS')}</BreadcrumbsItem>
      <BreadcrumbsItem to={`/telematics/accounts/edit/${accountId}`}>
        {t('BREADCRUMB.TELEMATICS_EDIT_ACCOUNT')}
      </BreadcrumbsItem>
      <TelematicAccountForm
        form={accountEditForm}
        submitDispatchFunction={dispatchTelematicAccountUpdate}
        submitLabelKey="COMMON.SAVE"
        telematicAccountId={accountForEdit.accountId}
        initialValues={mapAccountToForm(accountForEdit)}
        submitClicked={submitClicked}
        setSubmitClicked={setSubmitClicked}
      />
    </>
  );
}

TelematicAccountEditPage.propTypes = {};
