import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useHistory } from 'react-router-dom';
import {
  createTelematicAccount,
  submitUnknownTelematicProvider,
} from '../../../store/actions';
import { getSelectedCompany } from '../../../store/selectors';
import { ERROR_CODE } from '../../../utils/apiError';
import {
  isError, isSuccess, getErrorCode, getPayloadData,
} from '../../../utils/responseUtils';
import { uuidv4 } from '../../../utils/uuid';
import TelematicAccountForm from '../Components/TelematicAccountForm';

const TelematicAccountCreationPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [accountCreationForm] = Form.useForm();
  const contextCompanyId = useSelector(getSelectedCompany);
  const [submitClicked, setSubmitClicked] = useState(false);

  const confirmationModalKeysAndConstructor = (
    response, isUnknownProvider, handleUserNeedsSupport, goBackToTelematicsMain,
  ) => {
    const forSuccess = isSuccess(response);
    const forFailure = isError(response);
    const statusSuffix = (forSuccess && 'SUCCESS') || (forFailure && 'ERROR') || 'STARTED';
    const typeInterfix = isUnknownProvider ? 'UNKNOWN_PROVIDER' : 'NEW_ACCOUNT';
    const title = `TELEMATICS.ACCOUNT.CREATE.CONFIRMATION.TITLE.${typeInterfix}.${statusSuffix}`;
    const content = `TELEMATICS.ACCOUNT.CREATE.CONFIRMATION.CONTENT.${typeInterfix}.${statusSuffix}`;

    if (forSuccess) {
      const payloadData = getPayloadData(response);
      if ((payloadData.details && Object.keys(payloadData.details).length !== 0)) {
        const { writtenVins, failedVins } = payloadData.details;
        if (failedVins?.length > 0) {
          return {
            title: title.concat('.SCANIA_NOT_ALL_VEHICLES_ADDED'),
            content: <br />,
            constructor: Modal.warning,
            width: 472,
            onOk: goBackToTelematicsMain,
          };
        }
        if (failedVins?.length === 0) {
          return {
            title: title.concat('.DEFAULT'),
            content: t(content.concat('.SCANIA_ALL_VEHICLES_ADDED'), { addedVins: writtenVins.length }),
            constructor: Modal.success,
            onOk: goBackToTelematicsMain,
          };
        }
      }
      return {
        title: title.concat('.DEFAULT'),
        content: (
          <>
            {t(content.concat('.DEFAULT')).split('\n').map((line) => <p style={{ marginBottom: 0 }}>{line}</p>)}
          </>
        ),
        constructor: isUnknownProvider ? Modal.warning : Modal.success,
        onOk: goBackToTelematicsMain,
      };
    }

    const errorCode = getErrorCode(response);
    switch (errorCode) {
      case ERROR_CODE.SCANIA_VAT_NUMBER_NOT_FOUND:
      case ERROR_CODE.CONNECTIVITY_AGREEMENT_NOT_SIGNED:
        return {
          title: title.concat(`.${errorCode}`),
          content: t(content.concat(`.${errorCode}`)),
          constructor: Modal.error,
          width: 463,
          onOk: handleUserNeedsSupport,
          okText: 'TELEMATICS.ACCOUNT.CREATE.BUTTON_NEED_SUPPORT',
        };
      case ERROR_CODE.TELEMATIC_PROVIDER_ACCOUNT_EXISTS:
        return {
          title: title.concat('.DEFAULT'),
          content: t(content.concat(`.${errorCode}`)),
          constructor: Modal.error,
          width: 463,
        };
      default:
        return {
          title: title.concat('.DEFAULT'),
          content: t(content.concat('.DEFAULT')),
          constructor: forFailure ? Modal.error : Modal.info,
        };
    }
  };

  const dispatchTelematicAccountOrUnknownProvider = ({ payload, isUnknownProvider, handleUserNeedsSupport }) => {
    if (submitClicked) {
      return;
    }
    setSubmitClicked(true);
    const action = isUnknownProvider
      ? submitUnknownTelematicProvider
      : createTelematicAccount;
    dispatch(action(payload, contextCompanyId))
      .then((response) => {
        const modalInfo = confirmationModalKeysAndConstructor(
          response,
          isUnknownProvider,
          handleUserNeedsSupport,
          () => (history.push('/telematics')),
        );
        setSubmitClicked(false);
        modalInfo.constructor({
          key: uuidv4(),
          title: t(modalInfo.title),
          content: modalInfo.content,
          onOk: modalInfo.onOk,
          okText: t(modalInfo.okText),
          centered: true,
          width: modalInfo.width,
        });
      });
  };

  return (
    <>
      <BreadcrumbsItem to="/telematics">{t('BREADCRUMB.TELEMATICS')}</BreadcrumbsItem>
      <BreadcrumbsItem to="/telematics/accounts/create">{t('BREADCRUMB.TELEMATICS_NEW_ACCOUNT')}</BreadcrumbsItem>

      <TelematicAccountForm
        form={accountCreationForm}
        submitDispatchFunction={dispatchTelematicAccountOrUnknownProvider}
        creationView
        submitClicked={submitClicked}
        setSubmitClicked={setSubmitClicked}
      />
    </>
  );
};

TelematicAccountCreationPage.propTypes = {};

TelematicAccountCreationPage.defaultProps = {};

export default TelematicAccountCreationPage;
